import { flagSet } from "@coreui/icons";

const ORDER_STATUS = {
  CREATE: 0,
  PAYMENT_WAIT: 10,
  APPROVAL: 25,
  CANCELED: 50,
  ALERT: 75,
  PRODUCER_IN_CHARGE: 100,
  MARKET_ARRIVED: 200,
  MARKET_STOCK: 201,
  MARKET_IN_CHARGE: 205,
  ISLAND_ARRIVED: 300,
  ISLAND_IN_CHARGE: 305,
  FAMILY_IN_CHARGE: 400,
  DELIVERED: 500,
  PAID: 550,
  ERROR: 60,
};

const ORDER_ACTION = {
  CANCELLATION: "cancellation",
  DONE: "done",
  FILTER: "filter",
  INCIDENT: "incident",
  INCIDENT_EDIT: "incident_edit",
  UNDONE: "undone",
};

const LINES = {
  OTHER: 0,
  BOX: 1,
  FRIDGE: 2,
  SHIP: 3,
};

const INCIDENT_TYPE = {
  INCIDENT: "incident",
  CANCELLATION: "cancellation",
};

const INCIDENT_ENTITY_TYPE = {
  DDMPS: "ddmps",
  DDMIS: "ddmis",
  DETAILS: "details",
  ODETAILS: "odetails",
  PDETAILS: "pdetails",

  PLISTS: "plists",
  ORDERS: "orders",
  PORDERS: "porders",
};

const INCIDENT_ENTITY_TYPES = [
  INCIDENT_ENTITY_TYPE.DDMPS,
  INCIDENT_ENTITY_TYPE.DDMIS,
  INCIDENT_ENTITY_TYPE.DETAILS,
  INCIDENT_ENTITY_TYPE.ODETAILS,
  INCIDENT_ENTITY_TYPE.PDETAILS,
  INCIDENT_ENTITY_TYPE.PLISTS,
  INCIDENT_ENTITY_TYPE.ORDERS,
  INCIDENT_ENTITY_TYPE.PORDERS,
];

const INCIDENT_STATUS = {
  UNMANAGED: "unmanaged",
  MANAGED: "managed",
  CANCELLED: "cancelled",
};

const INCIDENT_ACTIONS = {
  CANCELLED: "cancelled",
  CANCELLED_FOR: "cancelled_for",
  REPLACEMENT: "replacement",
  DISCOUNT: "discount",
  CHANGE_WEIGHT: "change_weight",
};

const INCIDENT_ACTIONS_BATCH = {
  CANCELLED: "cancelled",
  CANCELLED_BY_PRODUCER: "cancelled_by_producer",
  CANCELLED_BY_MARKET: "cancelled_by_market",
  CANCELLED_BY_ISLAND: "cancelled_by_island",
  REMOVE_FROM_INCIDENT: "remove_from_incident",
};

const INCIDENT_ACTIONS_PRICE = {
  PROPORTIONALLY: "PROPORTIONALLY",
  CHARGE_PRODUCER: "CHARGE_PRODUCER",
  CHARGE_MARKET: "CHARGE_MARKET",
  CHARGE_ISLAND: "CHARGE_ISLAND",
};

const INVOICE_STATUS = {
  ISSUED: "ISSUED",
  TO_BE_ISSUED: "TO_BE_ISSUED",
};

const INVOICE_TYPE = {
  CUSTOMER: 1,
  MARKET: 2,
  PRODUCER: 3,
  ISLAND: 4,
};

const INVOICE_ACTIONS = {
  MARK_AS_CASHED: "MARK_AS_CASHED",
  CANCEL_CASHED: "CANCEL_CASHED",
  CANCEL_INVOICE: "CANCEL_INVOICE",
  DOWNLOAD_PDF: "DOWNLOAD_PDF",
  DOWNLOAD_CSV: "DOWNLOAD_CSV",
  MARK_AS_PAID: "MARK_AS_PAID",
  CANCEL_PAID: "CANCEL_PAID",
};

const INVOICE_ACTIONS_LABEL = {
  MARK_AS_CASHED: "segna come incassato",
  CANCEL_CASHED: "annulla incassato",
  CANCEL_INVOICE: "annulla fattura",
  DOWNLOAD_PDF: "scarica pdf",
  DOWNLOAD_CSV: "scarica csv",
  MARK_AS_PAID: "segna come pagato",
  CANCEL_PAID: "annulla pagato",
};

const AUTO_INVOICE_TYPE = {
  DATE: 1,
  AMOUNT_EXCEEDED: 2,
};

const AUTO_INVOICE_SCHEDULE = {
  MONTH_BEGINNING: 1,
  MONTH_10: 2,
  MONTH_15: 3,
  MONTH_END: 4,
};

const FAMILY_TYPE = {
  PRIVATE: "1",
  SOCIETY: "2",
  COOP: "3",
};

const languages = [
  { flag: flagSet.cifIt, language: "it", title: "Italiano" },
  { flag: flagSet.cifGb, language: "en", title: "English" },
  { flag: flagSet.cifDe, language: "de", title: "Deutsch" },
  { flag: flagSet.cifFr, language: "fr", title: "Français" },
];

const languageOptions = languages.map((item) => {
  return { value: item.language, label: item.title };
});

const producersProgressMap = [
  {
    id: 1,
    color: "cyan",
    label: "dal produttore",
    status: [ORDER_STATUS.PRODUCER_IN_CHARGE],
  },
  {
    id: 2,
    color: "green",
    label: "in magazzino",
    status: [ORDER_STATUS.MARKET_ARRIVED],
  },
  {
    id: 3,
    color: "brown",
    label: "pronto",
    status: [
      ORDER_STATUS.MARKET_IN_CHARGE,
      ORDER_STATUS.ISLAND_ARRIVED,
      ORDER_STATUS.ISLAND_IN_CHARGE,
      ORDER_STATUS.FAMILY_IN_CHARGE,
      ORDER_STATUS.DELIVERED,
      ORDER_STATUS.PAID,
    ],
  },
  {
    id: 4,
    color: "gray",
    label: "annullato",
    status: [ORDER_STATUS.CANCELED],
  },
  {
    id: 5,
    color: "emerald",
    label: "in stock",
    status: [ORDER_STATUS.MARKET_STOCK],
  },
  {
    id: 6,
    color: "pink",
    label: "in approvazione",
    status: [
      ORDER_STATUS.CREATE,
      ORDER_STATUS.PAYMENT_WAIT,
      ORDER_STATUS.APPROVAL,
      ORDER_STATUS.ALERT,
      ORDER_STATUS.ERROR,
    ],
  },
];

const ddmiProgressMap = [
  {
    id: 1,
    color: "cyan",
    label: "non pronto",
    status: [
      ORDER_STATUS.PRODUCER_IN_CHARGE,
      ORDER_STATUS.MARKET_ARRIVED,
      ORDER_STATUS.MARKET_STOCK,
    ],
  },
  {
    id: 2,
    color: "sand",
    label: "pronto/impacchettato",
    status: [ORDER_STATUS.MARKET_IN_CHARGE],
  },
  {
    id: 3,
    color: "green",
    label: "consegnato all’isola",
    status: [ORDER_STATUS.ISLAND_ARRIVED, ORDER_STATUS.ISLAND_IN_CHARGE],
  },
  {
    id: 4,
    color: "brown",
    label: "consegnato al cliente",
    status: [
      ORDER_STATUS.FAMILY_IN_CHARGE,
      ORDER_STATUS.DELIVERED,
      ORDER_STATUS.PAID,
    ],
  },
  {
    id: 5,
    color: "gray",
    label: "annullato",
    status: [ORDER_STATUS.CANCELED],
  },
  {
    id: 6,
    color: "pink",
    label: "in approvazione",
    status: [
      ORDER_STATUS.CREATE,
      ORDER_STATUS.PAYMENT_WAIT,
      ORDER_STATUS.APPROVAL,
      ORDER_STATUS.ALERT,
      ORDER_STATUS.ERROR,
    ],
  },
];


const ddmiProgressMapIsland = [
  {
    id: 1,
    color: "cyan",
    label: "non pronto",
    status: [
      ORDER_STATUS.PRODUCER_IN_CHARGE,
      ORDER_STATUS.MARKET_ARRIVED,
      ORDER_STATUS.MARKET_STOCK,
    ],
  },
  {
    id: 2,
    color: "sand",
    label: "In arrivo",
    status: [ORDER_STATUS.MARKET_IN_CHARGE, ORDER_STATUS.ISLAND_ARRIVED],
  },
  {
    id: 3,
    color: "green",
    label: "consegnato all’isola",
    status: [ORDER_STATUS.ISLAND_IN_CHARGE],
  },
  {
    id: 4,
    color: "brown",
    label: "consegnato al cliente",
    status: [
      ORDER_STATUS.FAMILY_IN_CHARGE,
      ORDER_STATUS.DELIVERED,
      ORDER_STATUS.PAID,
    ],
  },
  {
    id: 5,
    color: "gray",
    label: "annullato",
    status: [ORDER_STATUS.CANCELED],
  },
  {
    id: 6,
    color: "pink",
    label: "in approvazione",
    status: [
      ORDER_STATUS.CREATE,
      ORDER_STATUS.PAYMENT_WAIT,
      ORDER_STATUS.APPROVAL,
      ORDER_STATUS.ALERT,
      ORDER_STATUS.ERROR,
    ],
  },
];

const whProgressMap = [
  {
    id: 1,
    color: "cyan",
    label: "dal produttore",
    status: [ORDER_STATUS.PRODUCER_IN_CHARGE],
  },
  {
    id: 2,
    color: "sand",
    label: "consegnato al magazzino",
    status: [ORDER_STATUS.MARKET_ARRIVED, ORDER_STATUS.MARKET_STOCK],
  },
  {
    id: 3,
    color: "green",
    label: "pronto/impacchettato",
    status: [ORDER_STATUS.MARKET_IN_CHARGE],
  },
  {
    id: 4,
    color: "brown",
    label: "consegnato all’isola",
    status: [
      ORDER_STATUS.ISLAND_ARRIVED,
      ORDER_STATUS.ISLAND_IN_CHARGE,
      ORDER_STATUS.FAMILY_IN_CHARGE,
      ORDER_STATUS.DELIVERED,
      ORDER_STATUS.PAID,
    ],
  },
  {
    id: 5,
    color: "gray",
    label: "annullato",
    status: [ORDER_STATUS.CANCELED],
  },
  {
    id: 6,
    color: "pink",
    label: "in approvazione",
    status: [
      ORDER_STATUS.CREATE,
      ORDER_STATUS.PAYMENT_WAIT,
      ORDER_STATUS.APPROVAL,
      ORDER_STATUS.ALERT,
      // ORDER_STATUS.PRODUCER_IN_CHARGE,
      ORDER_STATUS.ERROR,
    ],
  },
];

const ordersProgressMap = [
  {
    id: 1,
    color: "cyan",
    label: "dal mercato",
    status: [
      ORDER_STATUS.PRODUCER_IN_CHARGE,
      ORDER_STATUS.MARKET_ARRIVED,
      ORDER_STATUS.MARKET_STOCK,
      ORDER_STATUS.MARKET_IN_CHARGE,
    ],
  },
  {
    id: 2,
    color: "sand",
    label: "da consegnare",
    status: [ORDER_STATUS.ISLAND_ARRIVED, ORDER_STATUS.ISLAND_IN_CHARGE],
  },
  {
    id: 3,
    color: "green",
    label: "consegnato al cliente",
    status: [ORDER_STATUS.FAMILY_IN_CHARGE],
  },
  {
    id: 4,
    color: "brown",
    label: "chiuso",
    status: [ORDER_STATUS.DELIVERED, ORDER_STATUS.PAID],
  },
  {
    id: 5,
    color: "gray",
    label: "annullato",
    status: [ORDER_STATUS.CANCELED],
  },
  {
    id: 6,
    color: "pink",
    label: "in approvazione",
    status: [
      ORDER_STATUS.CREATE,
      ORDER_STATUS.PAYMENT_WAIT,
      ORDER_STATUS.APPROVAL,
      ORDER_STATUS.ALERT,
      ORDER_STATUS.ERROR,
    ],
  },
];

const incidentProgressMap = [
  {
    id: 1,
    color: "cyan",
    label: "da gestire",
    status: [INCIDENT_STATUS.UNMANAGED],
  },
  {
    id: 2,
    color: "green",
    label: "gestito",
    status: [INCIDENT_STATUS.MANAGED],
  },
  {
    id: 3,
    color: "gray",
    label: "annullato",
    status: [INCIDENT_STATUS.CANCELLED],
  },
];

const roundListProgressMap = [
  {
    id: 0,
    color: "pink",
    label: "in approvazione",
    status: [
      ORDER_STATUS.CREATE,
      ORDER_STATUS.PAYMENT_WAIT,
      ORDER_STATUS.APPROVAL,
      ORDER_STATUS.ALERT,
      ORDER_STATUS.ERROR,
    ],
  },
  {
    id: 1,
    color: "cyan",
    label: "da ritirare",
    status: [ORDER_STATUS.PRODUCER_IN_CHARGE],
  },
  {
    id: 2,
    color: "sand",
    label: "da preparare",
    status: [
      ORDER_STATUS.MARKET_ARRIVED,
      ORDER_STATUS.MARKET_IN_CHARGE,
      ORDER_STATUS.MARKET_STOCK,
    ],
  },
  {
    id: 3,
    color: "orange",
    label: "in consegna",
    status: [ORDER_STATUS.ISLAND_ARRIVED, ORDER_STATUS.ISLAND_IN_CHARGE],
  },

  {
    id: 4,
    color: "green",
    label: "consegnato",
    status: [ORDER_STATUS.FAMILY_IN_CHARGE],
  },
  {
    id: 5,
    color: "brown",
    label: "chiuso",
    status: [ORDER_STATUS.DELIVERED, ORDER_STATUS.PAID],
  },
  {
    id: 6,
    color: "gray",
    label: "annullato",
    status: [ORDER_STATUS.CANCELED],
  },
];

export {
  ORDER_STATUS,
  ORDER_ACTION,
  LINES,
  INCIDENT_TYPE,
  INCIDENT_ENTITY_TYPE,
  INCIDENT_ENTITY_TYPES,
  INCIDENT_STATUS,
  INCIDENT_ACTIONS,
  INCIDENT_ACTIONS_BATCH,
  INCIDENT_ACTIONS_PRICE,
  INVOICE_STATUS,
  INVOICE_TYPE,
  INVOICE_ACTIONS,
  INVOICE_ACTIONS_LABEL,
  AUTO_INVOICE_TYPE,
  AUTO_INVOICE_SCHEDULE,
  FAMILY_TYPE,
  languages,
  languageOptions,
  producersProgressMap,
  ddmiProgressMap,
  ddmiProgressMapIsland,
  whProgressMap,
  ordersProgressMap,
  incidentProgressMap,
  roundListProgressMap,
};
