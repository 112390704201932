import VueI18n from "vue-i18n";

const initialize = (vue) => {
  vue.use(VueI18n);

  const dateTimeFormats = {
    it: {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      long: {
        year: "numeric",
        month: "short",
        day: "numeric",
        weekday: "short",
        hour: "numeric",
        minute: "numeric",
      },
    },
    en: {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      long: {
        year: "numeric",
        month: "short",
        day: "numeric",
        weekday: "short",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      },
    },
  };

  const loadLocaleMessages = () => {
    const locales = require.context(
      "../locales",
      true,
      /[A-Za-z0-9-_,\s]+\.json$/i
    );
    const messages = {};
    locales.keys().forEach((key) => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i);
      if (matched && matched.length > 1) {
        const locale = matched[1];
        messages[locale] = locales(key);
      }
    });
    return messages;
  };

  const i18n = new VueI18n({
    locale: window.env.I18N_LOCALE || "it",
    fallbackLocale: window.env.I18N_FALLBACK_LOCALE || "en",
    messages: loadLocaleMessages(),
    dateTimeFormats,
  });

  return i18n;
};

export default initialize;
